import { useBreakpoints as useVueBreakpoints } from '@vueuse/core';

export const useBreakpoints = () => {
  const breakpoints = useVueBreakpoints({
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  });

  return breakpoints;
};
